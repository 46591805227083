<template>
  <v-dialog
    v-model="isActive"
    persistent
    transition="dialog-transition"
    width="850"
    height="850"
  >
    <span class="d-none">{{ change }}</span>

    <v-card class="rounded-lg">
      <v-card-title>
        <v-spacer></v-spacer>
        <h6 class="text-h5 font-weight-bold text-center">
          <span class="font-work-sans"> Consultar </span>
        </h6>

        <v-spacer></v-spacer>

        <v-btn
          class="mr-n2 mt-n2"
          color="primary"
          icon
          @click="$emit('dismiss')"
          ><v-icon small>fa-times</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Número de Cédula:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-id-card</v-icon>
                </span>
                {{ `${patient.idType} - ${patient.idNumber}` }}
              </p>
            </div>

            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Nombre:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-user</v-icon>
                </span>
                {{ patient.firstName }}
              </p>
            </div>

            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Correo:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-envelope</v-icon>
                </span>
                {{ patient.email }}
              </p>
            </div>

            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Género:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-neuter</v-icon>
                </span>
                {{ sex(patient.gender) }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Fecha de Nacimiento:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-calendar-day</v-icon>
                </span>
                {{ date(patient.birthday) }}
              </p>
            </div>

            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Apellidos:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-user</v-icon>
                </span>
                {{ patient.lastName }}
              </p>
            </div>

            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Teléfono:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-envelope</v-icon>
                </span>
                {{ patient.cellphone }}
              </p>
            </div>

            <div>
              <p class="black--text font-weight-medium text-subtitle-1 mb-0">
                Dirección:
              </p>
              <p class="text-h6">
                <span class="mr-3">
                  <v-icon small color="primary">fa-map-marked</v-icon>
                </span>
                {{ patient.address }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="rounded-lg elevation-0 px-4 mb-2"
          @click="$emit('dismiss')"
        >
          <v-icon small>fa-check</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PatientDialog',
  props: {
    patient: { type: Object, default: {} },
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    sex: (value) => {
      const DEFAULT = 'Otro'
      const SEXS = {
        M: 'Masculino',
        F: 'Femenino',
      }
      return SEXS[value] || DEFAULT
    },
    date: (value) => {
      const date = new Date(value)
      date.setDate(date.getDate() + 1)
      return date.toLocaleDateString('es-VE')
    },
  },
}
</script>
