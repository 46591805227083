<template>
  <v-dialog
    v-model="isActive"
    persistent
    transition="dialog-transition"
    width="325"
    height="180"
  >
    <span class="d-none">{{ change }}</span>

    <v-card class="rounded-lg">
      <v-card-title>
        <h6 class="text-subtitle-1 font-weight-bold text-center ml-16">
          <span class="font-work-sans ml-5">Restaurar 2FA</span>
        </h6>

        <v-spacer></v-spacer>

        <v-btn
          class="mr-n5 mt-n2"
          color="primary"
          icon
          @click="$emit('dismiss')"
          ><v-icon small>fa-times</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <label for="" class="ml-4 black--text font-weight-medium"
            >Correo electrónico</label
          >
          <v-text-field
            placeholder="Correo electrónico"
            color="primary"
            rounded
            :rules="[rules.required, rules.email]"
            v-model="values.email"
            required
            prepend-inner-icon="fa-envelope"
            outlined
            class="mt-2 mb-n3"
          ></v-text-field>

          <label for="" class="ml-4 black--text font-weight-medium"
            >Teléfono</label
          >
          <v-text-field
            placeholder="Teléfono"
            color="primary"
            rounded
            :rules="[rules.required, rules.number]"
            v-model="values.cellphone"
            prepend-inner-icon="fa-phone-alt"
            required
            outlined
            class="mt-2 mb-n3 font-quicksand"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-n4">
        <v-btn
          color="primary"
          outlined
          class="rounded-lg px-4"
          @click="dismiss"
        >
          <v-icon small>fa-times</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="rounded-lg elevation-0 px-4"
          @click="submit"
          :loading="loading"
        >
          <v-icon small>fa-check</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
const PatientRepository = Repository.get('patients')

export default {
  name: 'RestorePatient',
  props: {
    patient: { type: Object, default: {} },
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    values: {
      email: '',
      cellphone: '',
    },
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      counter: (value) =>
        value?.length >= 8 || 'Contraseña mínima de 8 carácteres.',
      email: (value) => {
        const pattern = /.+@.+/
        return pattern.test(value) || 'Email inválido.'
      },
      number: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    submit: async function () {
      this.$refs.form.validate()

      setTimeout(async () => {
        if (this.valid) {
          await this.activeAlerts()
        }
      })
    },

    activeAlerts: async function () {
      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: 'Datos restaurados con éxito',
      }

      this.loading = true

      try {
        await PatientRepository.restore(this.values, this.patient.idNumber)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },

    dismiss: function () {
      this.$refs.form.reset()
      this.loading = false
      this.$emit('dismiss')
    },
  },
}
</script>
